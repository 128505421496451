define("firstsight-admin/templates/admin/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m9x5d9XH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center justify-center h-48 loading-route\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"loading...\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/admin/loading.hbs"
    }
  });

  _exports.default = _default;
});