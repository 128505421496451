define("firstsight-admin/templates/admin/approve-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SdW81N70",
    "block": "{\"symbols\":[\"user\",\"opts\"],\"statements\":[[4,\"user-listing\",null,[[\"users\"],[[24,[\"userList\"]]]],{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"border-b-4\"],[8],[0,\"\\n\"],[4,\"user-record\",null,[[\"user\",\"onPressVideo\"],[[23,1,[]],[23,2,[\"onPressVideo\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/admin/approve-videos.hbs"
    }
  });

  _exports.default = _default;
});