define("firstsight-admin/controllers/login", ["exports", "firebase/app", "firebaseui"], function (_exports, _app, _firebaseui) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    uiConfig: Ember.computed(function () {
      var _this = this;

      return {
        credentialHelper: _firebaseui.default.auth.CredentialHelper.NONE,
        callbacks: {
          signInSuccessWithAuthResult: function signInSuccessWithAuthResult() {
            _this.get('session').fetch().then(function () {
              try {
                var _this$get = _this.get('session.currentUser'),
                    uid = _this$get.uid,
                    email = _this$get.email,
                    displayName = _this$get.displayName;

                FS.identify(uid, {
                  displayName: displayName,
                  email: email
                });
              } catch (e) {// Eat
              }

              _this.transitionToRoute('admin');
            });
          }
        },
        signInOptions: [// firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        _app.default.auth.EmailAuthProvider.PROVIDER_ID]
      };
    })
  });

  _exports.default = _default;
});