define("firstsight-admin/templates/admin/suspended-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/uQU41Bx",
    "block": "{\"symbols\":[\"user\",\"opts\"],\"statements\":[[4,\"user-listing\",null,[[\"users\"],[[24,[\"userList\"]]]],{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"border-b-4\"],[8],[0,\"\\n\"],[4,\"user-record\",null,[[\"user\",\"onPressVideo\",\"showVideoActions\"],[[23,1,[]],[23,2,[\"onPressVideo\"]],false]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"user-actions flex flex-col justify-center\"],[8],[0,\"\\n        \"],[7,\"div\",false],[12,\"class\",[29,[\"bg-grey \",[28,\"if\",[[23,1,[\"taskIsRunning\"]],\"cursor-not-allowed opacity-50\",\"hover:bg-grey-dark\"],null],\" mb-4 text-center\"]]],[3,\"action\",[[23,0,[]],\"activate\",[23,1,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"taskIsRunning\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"fa-icon\",[\"circle-notch\"],[[\"spin\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\",true],[8],[0,\"Reactivate\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/admin/suspended-users.hbs"
    }
  });

  _exports.default = _default;
});