define("firstsight-admin/types/type-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isVideo = isVideo;
  _exports.isLocalRecording = isLocalRecording;
  _exports.objectValues = objectValues;
  _exports.objectEntries = objectEntries;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function isVideo(vid) {
    return !!vid && (typeof vid.url === 'string' || typeof vid.uploadUrl === 'string');
  }

  function isLocalRecording(vid) {
    return !!vid && typeof vid.localUri === 'string';
  }

  function objectValues(data) {
    return Object.values(data).filter(function (v) {
      return typeof v !== 'undefined';
    });
  }

  function objectEntries(data) {
    return Object.entries(data).filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          v = _ref2[1];

      return typeof v !== 'undefined';
    });
  }
});