define("firstsight-admin/templates/admin/promos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFflXJyR",
    "block": "{\"symbols\":[\"promo\"],\"statements\":[[7,\"ol\",true],[10,\"class\",\"list-reset\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"border-b-4 p-4 flex\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[8],[1,[23,1,[\"code\"]],false],[9],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[23,1,[\"type\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"campaign\",\"days\"]]],null,{\"statements\":[[0,\"          \"],[7,\"h4\",true],[8],[1,[23,1,[\"campaign\",\"days\"]],false],[0,\" days\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\",true],[8],[0,\"\\n          Max uses: \"],[1,[28,\"or\",[[23,1,[\"campaign\",\"count\"]],\"Unlimited\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/admin/promos.hbs"
    }
  });

  _exports.default = _default;
});