define("firstsight-admin/transforms/timestamp", ["exports", "ember-data", "firebase/app"], function (_exports, _emberData, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Timestamp = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.typeOf(serialized) === 'object' && serialized.toDate) {
        return serialized.toDate();
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (Ember.typeOf(deserialized) !== 'date') {
        return _app.default.firestore.FieldValue.serverTimestamp();
      }

      return deserialized;
    }
  });

  var _default = Timestamp;
  _exports.default = _default;
});