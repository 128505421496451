define("firstsight-admin/components/ember-popover", ["exports", "ember-tooltips/components/ember-popover"], function (_exports, _emberPopover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberPopover.default;
    }
  });
});