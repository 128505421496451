define("firstsight-admin/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    firebaseApp: Ember.inject.service() // setupController(...args) {
    //   this._super(...args);
    //   (async () => {
    //     debugger;
    //     const provider = new firebase.auth.EmailAuthProvider();
    //     const auth = await this.firebaseApp.auth();
    //     /* const creds: auth.UserCredential = */ await auth.signInWithPopup(
    //       provider
    //     );
    //     this.transitionToRoute('admin');
    //   })();
    // },

  });

  _exports.default = _default;
});