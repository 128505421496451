define("firstsight-admin/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ny8XpoVY",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[7,\"header\",true],[10,\"class\",\"py-8\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container mx-auto px-4 flex justify-between align-center\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"LOKO Admin\"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"underline text-blue cursor-pointer\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[0,\"\\n      Log out\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container mx-auto flex flex-col md:flex-row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"admin-link-container md:w-64\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"admin-links m-4\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"links\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"tagName\",\"route\"],[\"admin-link\",\"div\",[23,1,[\"target\"]]]],{\"statements\":[[0,\"          \"],[1,[23,1,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"route-container flex-1\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/admin.hbs"
    }
  });

  _exports.default = _default;
});