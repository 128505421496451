define("firstsight-admin/templates/components/user-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hsDyuoV/",
    "block": "{\"symbols\":[\"onPressVideo\",\"user\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"action\",[[23,0,[]],\"showVideo\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"users\",\"firstObject\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"displayUsers\"]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"        \"],[14,3,[[23,2,[]],[28,\"hash\",null,[[\"onPressVideo\"],[[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"border-b-4\"],[8],[0,\"\\n          \"],[1,[28,\"user-record\",null,[[\"user\",\"onPressVideo\"],[[23,2,[]],[23,1,[]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-center h-48 text-grey\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"no users\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"viewingUrl\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"video-player\",[28,\"component\",[\"video-player\"],[[\"url\",\"onClose\"],[[24,[\"viewingUrl\"]],[28,\"action\",[[23,0,[]],\"hideVideo\"],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/components/user-listing.hbs"
    }
  });

  _exports.default = _default;
});