define("firstsight-admin/templates/components/firebase-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ce8hFAx+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"id\",\"firebaseui-auth-container\"],[3,\"did-insert\",[[23,0,[\"startFirebaseUi\"]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/components/firebase-ui.hbs"
    }
  });

  _exports.default = _default;
});