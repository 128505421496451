define("firstsight-admin/types/enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ViolationType = _exports.DiffKind = _exports.PromotionType = _exports.NotificationType = _exports.AdminRole = void 0;
  // export enum MeetingResponseType {
  //   propose = 'propose',
  //   confirm = 'confirm',
  //   attend = 'attend',
  //   cancel = 'cancel',
  //   extend = 'extend',
  //   reset = 'reset',
  // }
  var AdminRole;
  _exports.AdminRole = AdminRole;

  (function (AdminRole) {
    AdminRole[AdminRole["admin"] = 11] = "admin";
    AdminRole[AdminRole["moderator"] = 4] = "moderator";
  })(AdminRole || (_exports.AdminRole = AdminRole = {}));

  var NotificationType;
  _exports.NotificationType = NotificationType;

  (function (NotificationType) {
    NotificationType["Welcome"] = "welcome";
    NotificationType["PreviewLike"] = "likepreview";
    NotificationType["FirstLike"] = "like";
    NotificationType["MutualLike"] = "mlike";
    NotificationType["MeetingPropose"] = "propose";
    NotificationType["MeetingConfirm"] = "conf";
    NotificationType["MeetingAttend"] = "attend";
    NotificationType["MeetingCancel"] = "cancel";
    NotificationType["MeetingMissed"] = "missed";
    NotificationType["MeetingUpcoming"] = "upcoming";
    NotificationType["AccountApproved"] = "approved";
    NotificationType["AccountReported"] = "reported";
    NotificationType["AccountSuspended"] = "suspended";
    NotificationType["AccountUnsuspended"] = "restored";
    NotificationType["AccountRestored"] = "restored";
    NotificationType["VideoRejected"] = "videorej";
    NotificationType["VideoRemoved"] = "videorem";
    NotificationType["WangoTangoPromo1"] = "contest";
  })(NotificationType || (_exports.NotificationType = NotificationType = {}));

  var PromotionType;
  _exports.PromotionType = PromotionType;

  (function (PromotionType) {
    PromotionType["FreeMeeting"] = "free-meeting";
    PromotionType["FreeExtend"] = "free-extend";
    PromotionType["FreeSubscription"] = "free-subscription";
    PromotionType["Discount"] = "discount";
  })(PromotionType || (_exports.PromotionType = PromotionType = {}));

  var DiffKind;
  _exports.DiffKind = DiffKind;

  (function (DiffKind) {
    DiffKind["new"] = "N";
    DiffKind["deleted"] = "D";
    DiffKind["edited"] = "E";
    DiffKind["array"] = "A";
  })(DiffKind || (_exports.DiffKind = DiffKind = {}));

  var ViolationType;
  _exports.ViolationType = ViolationType;

  (function (ViolationType) {
    ViolationType["IncorrectVideo"] = "incorrectvideo";
    ViolationType["InappropriateVideo"] = "badvideo";
    ViolationType["ObsceneVideo"] = "obscenevideo";
    ViolationType["Reported"] = "report";
  })(ViolationType || (_exports.ViolationType = ViolationType = {}));
});