define("firstsight-admin/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a6iABjQZ",
    "block": "{\"symbols\":[\"player\"],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"from-elsewhere\",null,[[\"name\"],[\"video-player\"]],{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/application.hbs"
    }
  });

  _exports.default = _default;
});