define("firstsight-admin/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tIsvK//F",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"set-body-class\",[\"video-player-visible\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"player-wrapper pin fixed flex items-center justify-center\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"player-background bg-black opacity-50 absolute w-full h-full\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"player-container p-8 z-10\"],[8],[0,\"\\n    \"],[7,\"video\",true],[10,\"controls\",\"\"],[11,\"src\",[22,\"url\"]],[10,\"height\",\"720\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "firstsight-admin/templates/components/video-player.hbs"
    }
  });

  _exports.default = _default;
});