define("firstsight-admin/components/impagination-dataset", ["exports", "impagination", "ember-impagination/components/impagination-dataset"], function (_exports, _impagination, _impaginationDataset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _impaginationDataset.default.extend({
    Dataset: _impagination.default
  });

  _exports.default = _default;
});